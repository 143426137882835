import React from 'react'
import Header from '../header/Header'
import Footer from "../footer/Footer";

import './NetworkInfra.css'

const NetworkInfra = () => {
  return (
    <>
    <Header/>
   
   
    <NetworkI/>
   
     
    <Footer/>
    </>
  )
}

export default NetworkInfra

const NetworkI = () =>{
  return(
    <>
    <div className="appl">
    <div className="netu2"> <p>We build end to end network Infra , starting from greenfield OFC construction in civil 
areas wherein our teams our adept at working in safe and efficient manner along with 
OFC laying in campus areas, onwards to indoor UTP cabling …
 we also do the server builds and Data Center low level works with OEMs like Vertiv and 
Rittal. We were the pioneers of adoption of the fiber channel based fabric interconnect 
based blade chassis installations wherein we have to our credit the first public sector 
blade Chassis/FI build deployment for Cisco UCS in India in the year 2010. 

      <br/><br/>  <b>Global eNetworks has to its credit experience of OFC deployments of more than 5000 kms.</b> It has delivered projects which have enabled energization of more than 80000 end points/User devices. 
Building Network Infra is a speciality of Global eNetworks . It has partnered with renowed Public sector units like BEL, ECIL and ERNET India to deliver and build OFC networks of national importance like-
LMC Connectivity across the Indian Navy
Delhi university ---PAN university network connecting all hostels/colleges/south and north campus
NHQ MAN (All Directorates/Secretariats---- across many locations in Delhi using OFC /UTP/Active Devices)
18 Infantry regimental centers of the Indian Army using more than 500 kms of OFC
CICP --- asset of 250 kms OFC was built across many units across the Nation in record time 

And several other projects across the country

Global eNetworks holds the design patent for the Marine Enclosure designed by it for outdoor use--- it is used for housing the active devices for LMC networks.     

<br/> <br/>We have several years of experience in putting together and integrating complex networks that meet your exact business requirements. Based on an understanding of your existing infrastructure, we design and implement a network that will keep pace with your company's growth. 
    <br/><br/> Given the fact, Security in any network is of utmost importance; Global eNetworks has a special focus in this domain. We not only provide connectivity by building networks but we do so by providing the connectivity in a secure manner. Special emphasis is given on designing security solutions, which helps enterprises protect their business information.
    <br/><br/>  We have a team of professionals which brings together a variety of experience of working in different environments such as Microsoft, Linux and VMWare and on different LAN/WAN technologies and on different Security products and tools such as Next Gen Firewalls, VPN, Intrusion detection, Anti APT etc. Our in-depth knowledge of internetworking products such as routers, multiplexers, switches, firewalls, security tools and cabling products and standards etc from industry leaders such as Cisco, VMWare, Microsoft, A10, NetApp, Palo Alto, Acer, Molex, Dlink, VTL, Paramount etc provides us an edge over our competitors.
    <br/><br/> <b> Our networking and security solutions include:
    <br/>   •	LAN/WAN design & implementation
    <br/>   •	Network auditing & assessment
    <br/>   •	Internet security/firewall/VPN design & implementation
    <br/>   •	Network management, analysis & planning
    <br/>   •	Data & voice integration
    <br/>   •	LAN/WAN Quality of Service solutions
    <br/>   •	Load balancing and fault tolerance solutions
    <br/>   •	Internet connectivity
    </b>
</p></div>
  
   </div>
   
   </>
  )
}