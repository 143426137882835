import React,{useState, useEffect} from 'react';
import './Slider.css';
import {SliderData} from './SliderData'

import { MdArrowForwardIos, MdArrowBackIosNew} from 'react-icons/md'

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideLength = SliderData.length;
    
   const autoScroll = true;
   let slideInterval;
   let intervalTime = 4000;

    const nextSlide = () =>{
    setCurrentSlide(currentSlide === slideLength -1 ? 0 : currentSlide +1)
     };

    const prevSlide = () =>{
    setCurrentSlide(currentSlide === 0 ? currentSlide + slideLength-1 : currentSlide -1)
     };

 

    useEffect(() =>{
      setCurrentSlide(0)
    },[])

    useEffect(() =>{
      if(autoScroll){
        auto();
      }
      return () => clearInterval(slideInterval);
    },[currentSlide])


  function auto() {
     slideInterval = setInterval(nextSlide,intervalTime);
  }


  return (
    <>
   <div className="slider">
    <MdArrowBackIosNew className='arrowprev' onClick={prevSlide}/>
    <MdArrowForwardIos className='arrownext'onClick={nextSlide}/>

    {
        SliderData.map((slide, index) => {
            return(
                <>
                <div className={index === currentSlide ? 'slidecurrent' : 'slide' } key={index}>
                  {index === currentSlide && (<>
                    <img src={slide.image} alt="slide"/>
                    <div className="content">
                      <h1>{slide.heading}</h1>
                      <p>{slide.desc}</p>
                      </div>
                      {/* <div className="container-dots">
                {Array.from({length: slideLength}).map((item, index) => (
                    <div 
                    onClick={() => moveDot(index )}
                    className={index === currentSlide ? "dot active" : "dot"}
                    ></div>
                ))}
                
            </div> */}
                      </>
                  )}
                 
                </div>
                </>
            )

        })
    }
   </div>
   <div className='slidingline'>
   <marquee > 
    <div className='slideliness'>
    <div>
   <a href="/ducts" style={{textDecoration:'none'}}> <h3>HDPE Ducts/Pipes Plant commissioned in Kundli factory on 24.05.2023 </h3> </a>
   </div> 
   <div>
   <a href="/jaljeewan" style={{textDecoration:'none'}} > <h3>Executing Jal Jeewan Mission Program for AFCONS Infrastructure Construction Engineering Company</h3> </a>
   </div>
    </div>
   
   
   </marquee>

   {/* <a href='https://www.google.co.in/maps/place/Global+eNetworks+Ltd/@28.6512695,77.2035924,17z/data=!4m5!3m4!1s0x390cfd65fecbbd3f:0x8293b78524c2ac3f!8m2!3d28.6512427!4d77.2058185' target='_blank' rel='noopener noreferrer'>
                 <img src={Map} alt =''  className='mapimg'/>
                  </a> */}
   </div>
           

</> )
}

export default Slider