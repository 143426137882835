import React, { useState } from 'react'
import './Header.css'
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

import { IoHome } from "react-icons/io5";





const Header = () => {

  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [showsmallIcons1, setShowsmallIcons1] = useState(false);
  const [showsmallIcons2, setShowsmallIcons2] = useState(false);

  return (
    <>

      <div className="grey">
        <p>Toll free no. 1800-11-8500 &nbsp;  | &nbsp;  Email : info@globalenetworks.com</p>
        <p>Office Hours : 09:30am - 05:30pm</p>
      </div>



      <div className="menuu">
        <div className="mobile-main">
          <div className='text'>GLOBAL eNETWORKS LTD</div>
          <div className="hamburger-menu">
            <a href='#' onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
        <ul className={showMediaIcons ? "main1" : "main"}>

          <li><Link to="/" style={{ textDecoration: 'none' }}> <div className='linkin'> Home</div></Link></li>


          <li> <Link to="" onClick={() => (<>{setShowsmallIcons1(!showsmallIcons1)}{setShowsmallIcons2(false)}</>)} style={{ textDecoration: 'none' }}><div className='linkin'>Profile</div></Link>
            <ul className={showsmallIcons1 ? "dropdownm" : "dropdownoff"}>
              <li><Link to="/contact" style={{ textDecoration: 'none' }}> <div className="linksmal">Contact Us</div></Link></li>
              <li><Link to="/companyprofile" style={{ textDecoration: 'none' }}> <div className="linksmal">Company Profile</div></Link></li>
              <li><Link to="/partners" style={{ textDecoration: 'none' }}> <div className="linksmal">Partners /Clients</div></Link></li>
              <li><Link to="/managementteam" style={{ textDecoration: 'none' }}> <div className="linksmal">Management Team</div></Link></li>


            </ul>
          </li>
          <li><Link to="" onClick={() => (<>{setShowsmallIcons2(!showsmallIcons2)}{setShowsmallIcons1(false)}</>)} style={{ textDecoration: 'none' }}><div className='linkin'>Services </div></Link>
            <ul className={showsmallIcons2 ? "dropdownm" : "dropdownoff"}>
              <li><Link to="/networkinfra" style={{ textDecoration: 'none' }}> <div className="linksmal">Network Infra</div></Link></li>
              <li><Link to="/securitysolution" style={{ textDecoration: 'none' }}> <div className="linksmal">Security Solutions</div></Link></li>
              <li><Link to="/systemintegration" style={{ textDecoration: 'none' }}> <div className="linksmal">System Integration</div></Link></li>
              <li><Link to="/applicationengineering" style={{ textDecoration: 'none' }}> <div className="linksmal">Application Engineering</div></Link></li>
              <li><Link to="/trainings" style={{ textDecoration: 'none' }}> <div className="linksmal">Trainings</div></Link></li>
            </ul>
          </li>

          {/* <li> <Link to=""  style={{ textDecoration: 'none' }}><div className='linkin'><br/> <br/> Products</div></Link>
      <ul className='dropdown'>
      <li><Link to="/PNetworkingPassive" style={{ textDecoration: 'none' }}> <div className="linksmal">Networking(Passive)</div></Link></li>
      <li><Link to="/PNetworkingActive" style={{ textDecoration: 'none' }}> <div className="linksmal">Networking(Active)</div></Link></li>
      <li><Link to="/PIT&Network" style={{ textDecoration: 'none' }}> <div className="linksmal">IT & Network Security</div></Link></li>
      <li><Link to="/PComputerSystem" style={{ textDecoration: 'none' }}> <div className="linksmal">Computer System</div></Link></li>
      </ul>
      </li> */}

          <li><Link to="/career" style={{ textDecoration: 'none' }}><div className='linkin'>Carees</div></Link></li>
          <li><Link to="/orders" style={{ textDecoration: 'none' }}><div className='linkin'>Orders</div></Link></li>
          {/* <li><Link to="/contact" style={{ textDecoration: 'none' }}><div className='linkin'>Contact</div></Link></li> */}

        </ul>

      </div>

      <div className='homecomp'>

        <ul className="main">
          <div className='text'>GLOBAL eNETWORKS LTD</div>
          <li><Link to="/" style={{ textDecoration: 'none' }}> <div className='linkin'><br /> <br /> <IoHome />Home</div></Link></li>


          <li> <Link to="" style={{ textDecoration: 'none' }}><div className='linkin'><br /> <br /> Profile</div></Link>
            <ul className='dropdown'>
              <li><Link to="/contact" style={{ textDecoration: 'none' }}> <div className="linksmal">Contact Us</div></Link></li>
              <li><Link to="/companyprofile" style={{ textDecoration: 'none' }}> <div className="linksmal">Company Profile</div></Link></li>
              <li><Link to="/partners" style={{ textDecoration: 'none' }}> <div className="linksmal">Partners /Clients</div></Link></li>
              <li><Link to="/managementteam" style={{ textDecoration: 'none' }}> <div className="linksmal">Management Team</div></Link></li>


            </ul>
          </li>
          <li><Link to="" style={{ textDecoration: 'none' }}><div className='linkin'><br /> <br />Services </div></Link>
            <ul className='dropdown'>
              <li><Link to="/networkinfra" style={{ textDecoration: 'none' }}> <div className="linksmal">Network Infra</div></Link></li>
              <li><Link to="/securitysolution" style={{ textDecoration: 'none' }}> <div className="linksmal">Security Solutions</div></Link></li>
              <li><Link to="/systemintegration" style={{ textDecoration: 'none' }}> <div className="linksmal">System Integration</div></Link></li>
              <li><Link to="/applicationengineering" style={{ textDecoration: 'none' }}> <div className="linksmal">Application Engineering</div></Link></li>
              <li><Link to="/trainings" style={{ textDecoration: 'none' }}> <div className="linksmal">Trainings</div></Link></li>
            </ul>
          </li>

          {/* <li> <Link to=""  style={{ textDecoration: 'none' }}><div className='linkin'><br/> <br/> Products</div></Link>
      <ul className='dropdown'>
      <li><Link to="/PNetworkingPassive" style={{ textDecoration: 'none' }}> <div className="linksmal">Networking(Passive)</div></Link></li>
      <li><Link to="/PNetworkingActive" style={{ textDecoration: 'none' }}> <div className="linksmal">Networking(Active)</div></Link></li>
      <li><Link to="/PIT&Network" style={{ textDecoration: 'none' }}> <div className="linksmal">IT & Network Security</div></Link></li>
      <li><Link to="/PComputerSystem" style={{ textDecoration: 'none' }}> <div className="linksmal">Computer System</div></Link></li>
      </ul>
      </li> */}

          <li><Link to="/career" style={{ textDecoration: 'none' }}><div className='linkin'><br /> <br />Career</div></Link></li>
          <li><Link to="/orders" style={{ textDecoration: 'none' }}><div className='linkin'><br /> <br />Orders</div></Link></li>
          {/* <li><Link to="/contact" style={{ textDecoration: 'none' }}><div className='linkin'>Contact</div></Link></li> */}

        </ul>

      </div>







    </>
  )
}

export default Header