import React from 'react'
import Header from '../header/Header'
import './Partners.css'


import C1 from '../assests/c1.png';
import C2 from '../assests/c2.png';
import C3 from '../assests/c3.png';
import C4 from '../assests/c4.png';
import C5 from '../assests/c5.png';
import C6 from '../assests/c6.png';
import C7 from '../assests/c7.png';
import C8 from '../assests/c8.png';
import C9 from '../assests/c9.png';
import C10 from '../assests/c10.png';
import C11 from '../assests/c11.png';
import C12 from '../assests/c12.png';
import C14 from '../assests/c14.png';
import C15 from '../assests/c15.png';
import C16 from '../assests/c16.png';


import P1 from '../assests/p1.png';
import P2 from '../assests/p2.png';
import P3 from '../assests/p3.png';
import P4 from '../assests/p4.png';
import P5 from '../assests/p5.png';
import P6 from '../assests/p6.png';
import P7 from '../assests/p7.png';
import P8 from '../assests/p8.png';
import P9 from '../assests/p9.png';
import P10 from '../assests/p10.png';
import P11 from '../assests/p11.png';
import P12 from '../assests/p12.png';
import P13 from '../assests/p13.png';
import P14 from '../assests/p14.png';
import P15 from '../assests/p15.png';
import P16 from '../assests/p16.png';
import P17 from '../assests/p17.png';
import P18 from '../assests/p18.png';

const Partners = () => {
  return (
    <> 
    <Header />
    <Partnerss/>
    </>
    
  )
}

export default Partners

const Partnerss = () =>{
  return(
    <>
     <div className='partner'>
      <h1>Our Partners</h1>
      <p>We associate with strategic innovators in the field of technology to provide our customers the access to the latest IT solutions and premium technical services. Together, we are helping government and corporate sectors with digital transformation and benefit from each other’s knowledge and experience.</p>
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P1} alt="" className='imageclient'/>
        <img src={P2} alt="" className='imageclient'/>
        <img src={P3} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P4} alt="" className='imageclient'/>
        <img src={P5} alt="" className='imageclient'/>
        <img src={P6} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P7} alt="" className='imageclient'/>
        <img src={P8} alt="" className='imageclient'/>
        <img src={P9} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P10} alt="" className='imageclient'/>
        <img src={P11} alt="" className='imageclient'/>
        <img src={P12} alt="" className='imageclient'/>
       
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P13} alt="" className='imageclient'/>
        <img src={P14} alt="" className='imageclient'/>
        <img src={P15} alt="" className='imageclient'/>
       
        
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P16} alt="" className='imageclient'/>
        <img src={P17} alt="" className='imageclient'/>
        <img src={P18} alt="" className='imageclient'/>
       
       
        </div>  
        <h5>All Logos and Trademarks are property of their Respective Owners</h5> 
      </div>
      <div className='partner'>
      <h1>Our Clients</h1>
      <p>We associate with strategic innovators in the field of technology to provide our customers the access to the latest IT solutions and premium technical services. Together, we are helping government and corporate sectors with digitization and benefiting from each other’s knowledge and experience.</p>
      </div>
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C1} alt="" className='imageclient'/>
        <img src={C2} alt="" className='imageclient'/>
        <img src={C3} alt="" className='imageclient'/>
        
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C4} alt="" className='imageclient'/>
        <img src={C5} alt="" className='imageclient'/>
        <img src={C6} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C7} alt="" className='imageclient'/>
        <img src={C8} alt="" className='imageclient'/>
        <img src={C9} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C10} alt="" className='imageclient'/>
        <img src={C11} alt="" className='imageclient'/>
        <img src={C12} alt="" className='imageclient'/>
       
        </div>  
      </div> 
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C14} alt="" className='imageclient'/>
        <img src={C15} alt="" className='imageclient'/>
        <img src={C16} alt="" className='imageclient'/>
        </div>  
        <h5>All Logos and Trademarks are property of their Respective Owners</h5> 
      </div> 

    </>
  )
}