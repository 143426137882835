import slide1 from '../images/slide1.png';
import slide2 from '../images/slide2.png';
import slide3 from '../images/slide3.png';
import slide4 from '../images/slide4.png';
import slide5 from '../images/slide5.png';
import slide6 from '../images/slide6.png';
import slide7 from '../images/slide7.png';
import slide8 from '../images/slide8.png';
import slide9 from '../images/slide9.png';
import slide10 from '../images/slide10.png';
import slide11 from '../images/slide11.png';
import slide12 from '../images/slide12.png';
import slide13 from '../images/slide13.png';


export const SliderData = [
    {
     image: slide1,
     heading: "Catalyst for Your Business Digital Transformation",
     Desc: "ones oneu a time ther ois onoly person lives between ",
    },
    {
        image: slide2,
        heading: " Collaborative & Fast Paced Application Deployment",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
       {
        image: slide3,
        heading: "Leading Network Integration Company in India",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
       {
        image: slide4,
        heading: "Providing Best in class Enterprise Connectivity solutions",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
       {
        image: slide5,
        heading: "Networking the Present to The future",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
       {
        image: slide6,
        heading: "Need Business Apps / Web Development Services..? Email or Call Us..!",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
       {
        image: slide7,
        heading: "Spotless Track Record Of On Time Project delivery",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
       {
        image: slide8,
        heading: "Trusted IT Vendor to our Armed forces for more than 20 years",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
       {
        image: slide9,
        heading: "Quick turnaround time for your IT consultancy needs",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
      
       {
        image: slide10,
        heading: "Design Patent holder of marine outdoor Networking Enclosures",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
       
       {
        image: slide11,
        heading: "Fast Execution of Network Infra projects",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
       {
        image: slide12,
        heading: "Email or Call us for Your Networking Requirements",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
        {
        image: slide13,
        heading: "Full Confidentiality Maintained... NDA Strictly Followed",
        Desc: "ones oneu a time ther ois onoly person lives between ",
       },
     
];

