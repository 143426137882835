import React from 'react'
import './ComputerSystem.css'

import Header from '../header/Header'

const ComputerSystem = () => {
  return (
    <>
    <Header />
 
    </>
  )
}

export default ComputerSystem