import React from 'react'
import Header from '../header/Header'
import Footer from "../footer/Footer";
import './CompanyProfile.css';

import Logi from '../assests/logo.png';

const CompanyProfile = () => {
  return (
    <>
    <Header/>
   
    
  <Cprofile/>
   

    
   
     
   <Footer />
    </>
  )
}

export default CompanyProfile

const Cprofile = () =>{
  return(
    <>
    
   <div className="prof">
    <div className="pro">
     <div style={{display:'flex'}}>
      <p><b>Global eNetworks Limited </b>was founded and promoted by young technocrats   heading the forefront of new technologies and new innovations in the IT and automation industry. The company is Part of the Rs 150 crore Bhai Asa Singh & Sons group having its headquarters in Delhi <br/><br/>Within a brief period, Global has grown to cater to various needs of clients. From the omnipresent personal computer, to enterprise servers, virtualization, onward to large network implementations and digital transformation - their designing, securing, implementing, maintaining and also extensively training the respective users at the organic levels in our fully equipped labs... Global vouchsafes a full-spectrum implementation of its esteemed subscribers.</p>
     <br/><br/><br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <div className='doen'><img src={Logi} alt= ' ' className='imagebody' /></div>
      </div>
   
     <br/><br/>   <h3>Areas of Competence</h3>
       <p>Global is a network integrator and complete IT service provider with a focus on digital transformation. We provide full spectrum IT solutions to meet the needs of the clients. Our areas of competence are given below</p>
  
     <br/> <h3><a href="/networkinfra">A) Network Infra Work Contracts </a> </h3>
   
   
    <br/>
    <h3> <a href="/securitysolution"> B) Security Solutions</a></h3>
    

    
    <br/>
    <h3><a href="/systemintegration">C) System Integration</a></h3>

    <br/>
    <h3> <a href="/applicationengineering">D) Application Engineering</a></h3>

    <br/>
    <h3> <a href="/trainings">E) Trainings</a> </h3>
   

    <br/>
    <h3>Key Projects</h3>
    <p>Global eNetworks has to it’s credit OFC deployments of more than 5000 kms and has contributed to end point energization vide UTP cabling and/or running applications for more than 80000 devices.
    <br/><br/> We have done various system and network integration projects for BEL, ECIL IBM, Indian Navy, ERNET India, Tech Mahindra, Delhi University, JNU, Jamia Millia Islamia University, Allahabad University, IIT Madras, Indian Air force, Indian Army, BEG-Roorkee, University of Rajasthan and many more esteemed clients.
    <br/><br/> Some of the projects executed by Global are as follows:
     </p>

     <p><b>CICP</b>--- Computerized Inventory control project. This was done along with IBM/L & T and involved deploying 250 kms of OFC Cabling work for the Indian Army across the Nation along with UTP cabling.
         <br/><br/><b>Messaging System for the Indian Navy</b>--- we have the privilege of having implemented the messaging project for the Indian Navy PAN India with state of the art hardware, server OS and virtualization, user nodes and network services and messaging software application installation using open source database.
         <br/><br/><b>OFC Laying project for the Infantry, Indian Army</b>--- Our company rolled out the OFC along with M/s PCS Tech across all infantry regimental centers across the Nation involving more than 500 kms of OFC Laying using the best practices of trenching and ducting/OFC Laying
         <br/><br/><b>Last Mile connectivity for the Indian Navy Backbone network</b>--- The OFC connectivity for the Indian Navy across India was implemented end to end using OFC , UTP cabling of more than 100 kms in the Naval Areas for facilitating the network access of remote units to the Naval backbone Network using our patented outdoor Enclosures along with Cisco active devices and numerous network accessories. 
           </p>
   <div className="chan">
    <p>
  <b  >Indian Air Force - Integrated Material Management Online Systems (IMMOLS) :</b>   The project involved supply & installation of Hardware, Software, Network Items and associated peripherals for 26 Additional sites of Indian Air Force for IMMOLS Application. The implementation involved installation of around 600 nodes over UTP CAT-6 and approximately 27 Kms of fibre optic cabling.
<br/><br/>& many more technology deployments in the Armed Forces /Defence Sector
<br/><br/> <b>ERNET India (An Autonomous Scientific Society under Department of Information Technology, GoI) - Outsourcings of Technical Manpower for Various PoP of ERNET India :</b>  ERNET India is providing Intranet and Internet connectivity to Educational and Research Organizations in the country. The connectivity is provided through its various Point of Presence (PoPs) across the country. These PoPs are connected on terrestrial backbone and are equipped with state of the art equipments such as Servers, Firewall, Antivirus Gateway, High-End Router and RAS. For this kind of requirement we are providing technical services to ERNET India for managing their PoPs. As on date we have positioned 20 Engineers for the same.

<br/><br/><b>IIT Chennai : </b>India's first 10 Gigabit Campus Wide Network implemented using 24/12 core optical fiber cables connecting all the staff quarters, hostels and departments. Over 5000 nodes form part of the Igloo net. The key differentiating factor of this project is the installation of Core LAN switches housed in weather proof racks in open areas having cooling units and remote monitoring capabilities.
<br/><br/><b>Allahabad University - Campus Wide Network :</b> The project focused on design, installation, configuration and maintenance of Campus Wide Network including supply and laying of fiber and UTP cable and components. The implementation involved installation of around 2000 nodes over Cat 5E UTP cable and approximately 20 Kms of Fiber Optic cable.
<br/><br/><b>Delhi University - Campus Wide Network :</b> The project consisted of setting up a campus network to connect all the colleges, departments and hostels of Delhi University. It encompasses 3200 nodes connected on Cat 5E UTP cable and 43 Kms of Fiber Optic cabling.
<br/><br/><b>Jamia Milia Islamia University - Campus Wide Network : </b>The project focused on design, installation, configuration and maintenance of Campus Wide Network including supply and laying of fiber and UTP cabling and components. The Implementation involved installation of around 750 nodes over Cat 5E UTP cable and approximately 10 Kms of Fiber Optic cable and energising the network using Cisco switches.
<br/><br/><b>DoTSoft Implementation for Department of Telecommunications : </b>(now known as Bharat Sanchar Nigam Ltd). The project involved installation and commissioning of systems, network and application software spread across more than 100 locations for automated billing system in Jammu and Srinagar Circles. It involved setting up of LAN and WAN using Cisco, RAD and 3Com equipment.
<br/><br/><b>Computerisation at Engineer-in-Chief Branch, Army Headquarters : </b>The project involved supply and installation of 525 Nos. of Personal Computers and associated peripherals for various establishments of Engineer-in-Chief branch. 
<br/><br/><b>Internetwork connectivity at Regional Engineering College, Kurukshetra :</b> The project involved supply and installation of Cisco routers with the associated peripherals for Internetwork Connectivity at the Regional Engineering College, Kurukshetra. 
<br/><br/><br/><br/>Contact us for any requirements at <span style={{color:'blue'}}> Info@GlobaleNetworks.com</span>
    </p>
    
   </div>
   
   
    </div>
   </div>

    </>
  )
}