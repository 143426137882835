import React from 'react'

import Header from '../header/Header'
import Footer from "../footer/Footer";

import './SecuritySolution.css'

const SecuritySolution = () => {
  return (
    <>
    <Header/>
   
   
<SecurityD/>
   
     
    <Footer/>
    </>
  )
}

export default SecuritySolution

const SecurityD = () => {
  return(
    <>
    
  <div className="serv">
    <div className="serv2">  <p>Given the fact, Security in any network is of utmost importance; Global eNetworks has a special 
focus in this domain. We not only provide connectivity by building networks but we do so by 
providing the connectivity in a secure manner. Special emphasis is given on designing security 
solutions, which helps enterprises protect their business information.


      
      <br/><br/><b>Global eNetworks Ltd’s</b> first ever project was from a Defence organization of the Navy for deployment of computer security product and services. After that it has gone from strength to strength in delivery and successful life cycle management of IT service projects.
It has recently worked with Tech Mahindra for a SOC Operations Services project. 
Company has extensive work experience of outsourcing of IT services from ERNET India for which it used to manage the NOC/SOC operations in many locations country wide.
Global eNetworks  is actively rendering IT services for mission critical messaging and LMC projects of the Indian Navy and has assigned multiple resources to deliver the IT services SLA to the users.
We provide top quality resources at affordable prices, our resources have integrity and domain knowledge & they are assets to the organization where ever assigned.
<br/>Every organization faces challenges that are peculiar to its business and its vision for the future. Accordingly, the system integration needs of different enterprises would vastly differ for enhancing productivity using digital means.
       <br/><br/>The digital needs of a Defence organization will differ than that of a  corporate—the former may have more focus on security and will require on premise digital deployments for it’s mission critical projects as compared to the latter who may not hesitate to have cloud based deployment for projects like online sales and logistics. 
      
       <br/><br/> 
         Keeping this in mind, Global has developed the capability to offer end-to-end system 
         integration security solutions custom-designed to meet all business need. In fact, we 
         excelled in the area of providing robust and current security solutions. 
         <br/><br/><b>We do end to end Internet security/firewall/VPN design & implementation / IAM /PAM / Patch Management Solutions</b>
       <br/><br/> We have the support of global leaders and Indigenious Players to deliver the latest technologies at competitive prices. We have also acquired expert knowledge in several core technologies to offer the right solutions to our customers. Our team of skilled engineers uses best practices and proven methodologies to manage mission-critical projects that gives our customer competitive advantage over others. Besides, our countrywide service ensures that you get the best service and digital deployment for your specific needs when and where you need it.
       </p></div>
   
  </div>
  
   
    </>
  )
}