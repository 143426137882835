import React from 'react'
import './NetworkingActive.css'

import Header from '../header/Header'

const NetworkingActive = () => {
  return (
   <>
   <Header />
   <div className="nepr">
    
   </div>
   </>
  )
}

export default NetworkingActive