import React from 'react'
import Header from '../header/Header'
import './ManagementTeam.css'
import manangementTeam1 from '../images/managementTeam1.jpg'
import manangementTeam2 from '../images/managementTeam2.png'

const ManagementTeam = () => {
  return (
    <>
    <Header/>
   <ManagementTe/>
    </>
  )
}

export default ManagementTeam

const ManagementTe = () =>{
  return(
    <div className='manage'>
    <h3>Our Management Team</h3>
  <p>Global eNetworks is led and founded by <b> Sarpreet Singh Chadha </b> , BE (Electronics & Communications) from Delhi Institute of Technology affiliated to Delhi University (now NSUT).
He has wide experience of working in the IT Industry for more than 25 years and has spearheaded many an important project like the DOTSOFT project in critical areas like 100+ remote areas in Jammu and Kashmir  from 1999 till 2007,  Delhi University CWN project, IIT Madras Igloonet project, INS Vikramaditya ALAN project, Last mile connectivity project for the Indian Navy, messaging project for the Navy , countrywide deployment of OFC for all infantry regimental centers and many others.
</p>
<p>  <b>Pradeep Kumar-</b>  Pradeep is a B Com graduate from Delhi University with more than 24 years’ experience and  leads the finance and accounts work at Global eNetworks. Adoption of countrywide system of daily settlement of accounts Project wise has been his seminal contribution to the company.  A man of integrity and discipline , Pradeep inspires the youngsters in the company by his poise and honesty. 

<br/><br/>

{/* <br/><br/> <b>Ajay Kumar---</b>   Ajay is a BE from IETE in Electronics & Telecommunications & MBA. A tireless and sincere engineer with a keen mind , Ajay absorbs the minutest details while configuring the active devices and various sub systems --- he carries the responsibility of enabling the various multi OEM active devices to talk to each other. He excels in counseling the team of system engineers for the countrywide deployments for specific project that he is assigned to. Ajay too has experience of 16 years with the company. */}

<br/><br/><b>Anu Dhamija---</b>    Anu , who has been with us for 15 years, is a graduate from Kurukshetra University ,and  is the company service  coordinator and contributes immensely for ensuring the uptime for the client projects as per the committed SLAs. She also looks after logistics support for the company to ensure timely material deliveries. Her focus and determination to meet timelines make her an asset to the organization.

</p>


<img src={manangementTeam1} alt="" className='imagia' />
<img src={manangementTeam2} alt="" className='imagia' />

  </div>
 

  )
}