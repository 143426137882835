import React from 'react';
// import { useState } from "react";

import Header from './header/Header';
import Footer from "./footer/Footer";
import './Career.css';

// import Button from '@mui/material/Button';
// import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const Career = () => {

  // const [color, setColor] = useState(false);
  const color = false;
  return (
    <>
    <Header/>
   
    <br/> <br/>
    
   <div className="career">
    <div>
 <div className="carri">
  <div className={color?'carri2' : 'carri1'}>
 <br/>
  <h1>Welcome ! To Global eNetworks Ltd</h1>
  <br/><br/>
  <h2>Looking for an opportunity in Global eNetworks ?</h2>
  <p>We are Leading Company in Providing IT Services all Over India .
   <br/> We are Trusted By The Indian Army , IndianNavy, DRDO, and Many
   <br/>Government Organisations. and provide Our Services to reputed
   <br/>companies like Bharat Electronics Ltd, Electronics Corporation of India,
   <br/>Tech Mahindra, IBM (now Kyndryl), STL etc.</p>
  <p> Joining Our company will help further your career and Growth. It will
  <br/>give You Challenges and will make you learn new skills and experiences
  <br/>if you are dedicated to your profession.</p>
  <p>Global eNetworks Ltd. is an equal opportunity firm, offering excellent
  <br/>career opportunities. Our consultants go through rigorous technical
  <br/>and personal sessions to help them provide the best solutions in the
  <br/>market to our clients. If you have fire in your belly and sparkle in your
  <br/>eyes, then you are someone we are looking for.</p>
  <p>Email us at <span style={{color:'blue'}}>Jobs@GlobaleNetworks.com</span> today with your CV as a Word
  <br/> file attachment. We keep posting new vacancies in this section from
   <br/>time to time</p>
 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       
     
   {/* <Button variant="outlined" href="#outlined-buttons" onClick={() => {
    setColor(!color);
  }}>
        Next <BsArrowRight/>
      </Button> */}
      </div>
{/* 
      <div className={color?'tablesty' : 'carri2'}>
 <table className='table'>
        <tr>
          <th>S.no</th>
          <th>Job Title</th>
          <th>Opening Date</th>
          <th>Last Date</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>1</td>
          <td>software engineer </td>
          <td>06/07/22</td>
          <td>16/07/2022</td>
          <td>detail here</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Hardware</td>
          <td>06/07/22</td>
          <td>16/07/2022</td>
          <td>detail here</td>
        </tr>
        <tr>
          <td>3</td>
          <td>It Support </td>
          <td>06/07/22</td>
          <td>16/07/2022</td>
          <td> detail here</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Fittings </td>
          <td>06/07/22</td>
          <td>16/07/2022</td>
          <td>detail here</td>
        </tr>
       
      </table>
      <br/><br/><br/><br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       
   <Button variant="outlined" href="#outlined-buttons" onClick={() => {
    setColor(!color);
  }}>
    <BsArrowLeft/> Back
      </Button>

     
      </div>  */}

 </div>
 
      </div>
   </div>
 

    <br/> <br/><br/>
    
    
   
     
    <Footer/>
    </>
  )
}

export default Career