import React from 'react'
import './Ducts.css'
import ductImage1 from '../images/ductImage1.jpg';
import ductImage2 from '../images/ductImage2.jpg';
import ductImage3 from '../images/ductImage3.jpg';
import ductImage4 from '../images/ductImage4.jpg';

const  Ducts = () => {
  return (
    <div className="container">
    <h1 className="title">Our New Plant Opens in Kundli of HDPE Ducts/pipes .</h1>
    <p className="description">Please call / whatsapp / email far requirement fulfillment;</p>
      <p className="description">call on : 9350531149</p>
      <p className="description">whatsapp : 9311375628</p>
      <p className="description">email  prsj@globalenetworks.com</p>
      <img className="centered-image" src={ductImage1} alt="Image 1" />
      <img className="centered-image" src={ductImage2} alt="Image 2" />
      <img className="centered-image" src={ductImage3} alt="Image 3" />
      <img className="centered-image" src={ductImage4} alt="Image 4" />
  </div>
  )
}

export default Ducts