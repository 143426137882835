import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div className="main2">
     
       <div className='data'>
        <div>
          <h3>Registered Office </h3>
          <p>33, Rani Jhansi Road,<br/>New Delhi-110055<br/> (INDIA)</p><br/>
          <h3>Phone </h3>
          <p>+91-11-23673393 <br/> +91-11-23518502</p>
          <h3>Fax </h3>
          <p>+91-11-23556433</p>
          <h3>Email </h3>
          <p>info@globalenetworks.com</p>
        </div>
        {/* second line */}
        <div>
          <h3>SINGAPORE BRANCH</h3>
          <p>10, JELAN BESAR, # 10-12,<br/> SINGAPORE – 208787<br/> <br/><b>Phone</b>   # 0065-62938089 </p>
          <br/>
          <h4>Corporate Office </h4>
          <p>721, Phase V, Udyog Vihar,<br/> Gurgaon, Haryana-122022</p><br/>
          <h4>Factory</h4>
          <p>Plot No. 346, Phase 5,<br/>Sector 56, Sonipat, Kundli</p>
         
        </div>
        {/* third line */}
        <div>
      <h3>Few of Our Service Locations </h3>
      <div className="sites">
        <div>
          <h3>Location</h3>
         <p>&nbsp;&nbsp;Mumbai</p>
         <p>&nbsp;&nbsp;Vizag</p>
         <p>&nbsp;&nbsp;Portblair</p>
         <p>&nbsp;&nbsp;Kochi</p>
         <p>&nbsp;&nbsp;Karwar</p>
         <p>&nbsp;&nbsp;Gurgaon</p>
         <p>&nbsp;&nbsp;Jabalpur</p>
        </div> 
        &nbsp;&nbsp;&nbsp;
        <div>
          <h3>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contact</h3>
          <p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9821662718</p>
          <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7013379039</p>
          <p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
          <p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9350530944</p>
          <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8851779923</p>
          </div>
      </div>
     
        </div>
        </div>
        <div className='copyright'>
          <p>Copyright @2022  <br/>All Right Reserved<br/></p>
        </div>
    </div>
  )
}

export default Footer