import React from 'react';
import './JalJeevan.css'
import jaljeevanImage1 from '../images/jaljeevanImage1.jpg';
import jaljeevanImage2 from '../images/jaljeevanImage2.jpg';
import jaljeevanImage3 from '../images/jaljeevanImage3.jpg';
import jaljeevanImage4 from '../images/jaljeevanImage4.jpg';
import jaljeevanImage5 from '../images/jaljeevanImage5.jpg';

const  JalJeevan = () => {
  return (
    <div className="container">
      <h1 className="title">Manufacturing pipes from 20mm to 110mm.</h1>
      <p className="description">Please call / whatsapp / email far requirement fulfillment;</p>
      <p className="description">call on : 9350531149</p>
      <p className="description">whatsapp : 9311375628</p>
      <p className="description">email  prsj@globalenetworks.com</p>
      <img className="centered-image" src={jaljeevanImage1} alt="jaljeevanImage 1" />
      <img className="centered-image" src={jaljeevanImage2} alt="jaljeevanImage 2" />
      <img className="centered-image" src={jaljeevanImage3} alt="jaljeevanImage 3" />
      <img className="centered-image" src={jaljeevanImage4} alt="jaljeevanImage 4" />
      <img className="centered-image" src={jaljeevanImage5} alt="jaljeevanImage 5" />
    </div>
  )
}

export default JalJeevan


