import React from 'react'
import './SystemIntegration.css'

import Header from '../header/Header'
import Footer from "../footer/Footer";

const SystemIntegration = () => {
  return (
    <>
    <Header/>
    <SystemD/>
    <Footer/>
    </>
  )
}

export default SystemIntegration

const SystemD = () =>{
  return(
    <>
    <div className="bppl1">
      <div className="bppl2">
     <p>
    <b> Today the biggest challenge is to get different OEM builds to talk to each other 
seamlessly with as low latency as possible. And our engineers are experts exactly 
at this.</b>

<br/><br/>We have a team of professionals which brings together a variety of experience of working in 
different environments such as Microsoft, Linux and VMWare and on different LAN/WAN 
technologies and on different Compute/storage/Security products and tools such as HCI, blade 
servers, SAN/NAS Storage ,Rack servers, Next Gen Firewalls, VPN, Intrusion detection, Anti APT, 
IDAM/PAM solutions, patch management solutions etc. Our in-depth knowledge of 
internetworking products such as routers, servers, switches, storage, firewalls, security tools and 
digital solutions from industry leaders such as Cisco, VMWare, Microsoft, A10, NetApp, Palo 
Alto, Acer,  Dlink, etc provides us an edge over our competitors.
<br/> <br/><b>Our Team has Strong Skills in Virtualization and Backup Solutions as also Containerization of Critical Apps and Builds.</b>

<br/><br/>
The first ever public sector deployment of Cisco blade servers in a complex application porting 
environment on Linux was done by us in the year 2010 …. This involved integration with TACACS 
server ISE based security appliances along with ASA Series firewalls and encryption equipment 
of the highest grade on carrier grade routers backbone networks PAN Nation.
<br/><br/>Keeping in view the indigenous push for the local products we have tied up with local OEMs like 
Gajshield, Infinity Labs, eMudhra, StackUp , Motadata for their solutions like Patch management, 
Next Gen Firewalls, SDWAN, Hyper Converged Infra, software defined storage (SDN) and more….

<br/><br/>
Our huge collective experience of our engineers and technicians solve problems of complex 
nature involving system integration needs of our clients in a quick and safe manner. 



     </p>

      </div>
    </div>
    </>
  )
}