import React from 'react'
import Header from '../header/Header'
import Footer from "../footer/Footer";

import './Trainings.css'


const Trainings = () => {
  return (
    <>
    <Header/>
   
    <TrainingD/>
    
   
     
    <Footer/>
    </>
  )
}

export default Trainings

const TrainingD = () => {
  return(
    <>
    
    <div className="appl1">
      <div className="appl2"> <p>
        
      We believe in not just attaining new skills and knowledge for ourselves, but we also help our customers to attain more skills and knowledge so that they can use IT more efficiently and effectively to run their business. For this, we utilize facilities of our state of the art lab at Delhi to impart training to the staff of our customers. We have already successfully imparted trainings to the network professionals and consultants of <b>Wipro, Price Waterhouse, NIIT, Microland, Ranbaxy,</b>  Estel Networks, HCL Technologies, Hughes Escorts Communications Limited, Indian Navy, and various representatives of the Universities under UGC,& personnel of IARI, ERNET India etc.
    <br/><br/>   We have some of the best instructors for our systems and network courses and having extensive experience of implementing large corporate networks. The training offerings address both certification requirements as well as customized needs. The current offerings cover the complete IT needs right from the basic user level training for familiarization to Computers and Networks to advanced and complex concepts such as<b> Security, data/voice integration, special projects etc.</b>
    <br/><br/>
Contact us for corporate trainings at Info@GlobaleNetworks.com
<br/><br/>Information on the Internet is cluttered and focused training modules are the need of the day. We provide professional corporate training to a wide range of industries.
<br/><br/>  <b> Training Modules</b>
<br/>Our corporate training modules include:
<br/>•	Networking
<br/>•	Systems Curricula
<br/>•	Security
<br/>•	Computers & Office Automation
<br/><br/><b>Training Imparted</b>
<br/><br/>Global eNetworks has successfully imparted trainings to network professionals and consultants of known names such as Wipro, Price Waterhouse, NIIT, Microland, Ranbaxy, Estel Networks, HCL Technologies, Hughes, and Escorts amongst others.
          Our state-of-the-art lab in New Delhi is well equipped for imparting training to company staff and consultants.

          <br/><br/><b>Instructors</b>
          <br/><br/>Our instructors are some of the best in the industry, trained in Singapore with extensive experience of implementing large corporate networks. The training covers both certification requirement as well as customized need.

          <br/>InstructorsThe current training courses cover complete IT needs right from basic level training, familiarizing with computers and networking, to advanced and complex concepts like Security, Data/Voice Integration etc.


     </p></div>
     
    </div>
   
  
    
    </>
  )
}