import React from 'react'
import { Routes, Route, } from "react-router-dom";
import './App.css';

import Home from './component/Home'


import Contact from './component/Profile/Contact'
import CompanyProfile from './component/Profile/CompanyProfile'
import Partners from './component/Profile/Partners'
import ManagementTeam from './component/Profile/ManagementTeam';

import NetworkInfra from './component/Services/NetworkInfra'
import SecuritySolution from './component/Services/SecuritySolution'
import SystemIntegration from './component/Services/SystemIntegration';
import ApplicationEngineering from './component/Services/ApplicationEngineering';
import Trainings from './component/Services/Trainings'

import ComputerSystem from './component/Productcomp/ComputerSystem'
import ITNetworksecurity from './component/Productcomp/ITNetworksecurity'
import NetworkingPassive from './component/Productcomp/NetworkingPassive'
import NetworkingActive from './component/Productcomp/NetworkingActive'



import Career from './component/Career';

import Orders from './component/orders/Orders';
import Ducts from './component/pages/Ducts';
import JalJeevan from './component/pages/JalJeevan';




const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="contact" element={<Contact />} />
      <Route path="companyprofile" element={<CompanyProfile />} />
      <Route path="partners" element={<Partners />} />
      <Route path="managementteam" element={<ManagementTeam />} />

      <Route path="securitysolution" element={<SecuritySolution />} />
      <Route path="networkinfra" element={<NetworkInfra />} />
      <Route path="systemintegration" element={<SystemIntegration />} />
      <Route path="applicationengineering" element={<ApplicationEngineering />} />
      <Route path="trainings" element={<Trainings />} />

      <Route path="PNetworkingPassive" element={<NetworkingPassive />} />
      <Route path="PNetworkingActive" element={<NetworkingActive />} />
      <Route path="PIT&Network" element={<ITNetworksecurity />} />
      <Route path="PComputerSystem" element={<ComputerSystem />} />

      <Route path="career" element={<Career />} />

      <Route path="orders" element={<Orders />} />

      <Route path="ducts" element={<Ducts />} />

      <Route path="jaljeewan" element={<JalJeevan />} />
    </Routes>
  )
}



export default App



