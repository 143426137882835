import React from 'react'
import './ApplicationEngineering.css'

import Header from '../header/Header'
import Footer from "../footer/Footer";

const ApplicationEngineering = () => {
  return (
    <>
    <Header/>
   
  
    <ApplicationData/>
    
   
     
    <Footer/>
    </>
  )
}

export default ApplicationEngineering

const ApplicationData = () =>{
  return(
    <>
     <div className="appl1">
    <div className="appl2">
    <p>Global eNetworks boasts of strong capabilities in delivery of application engineering 
solutions. It has resources adept in various frameworks & Languages like <b>.Net, Java, php , 
Python, react js, & node js.</b>Global has embarked on the mission to help organizations adopt 
state of the art devOps tools for maximizing productivity and minimizing costs. SDLC is 
a capability on which Global eNetworks has a big focus on.


<br/><br/><b>We have resources  working on AI(Artificial Intelligence),ML (Machine Learning) tools like Tensorflow, R</b>

<br/><br/><br/><br/>Global eNetworks has deployed applications of mission critical nature for Clients 
of National importance like the Indian Navy. It has a legacy of years of work in this 
area of application specific solutions like the ASMS tools in the Indian Air Force 
and the tools for integrating Open source db with inventory control applications 
like IMMOLS for the Indian Air Force.
<br/><br/>Global has its roots in the development of vendor /inventory control application 
integration of Automobile giant MARUTI UDYOG with its vendors in the North 
region of the country when it did pioneering work in this regard many years back. 
The company has carried on with it‘s legacy of state of the art application 
deployments.

Company has a strong application engineering team for your app development 
requirements and we look forward to your enquiries in this regard. 
</p>
    </div>
   </div>
   
    </>
  )
}