import React from 'react'
import './Home.css'

import Header from './header/Header';     
import Footer from "./footer/Footer";
import Slider from './slider/Slider';

import { FiPhone, FiMail, FiPrinter } from "react-icons/fi";
import Map from './assests/map.png';

import Logi from './assests/logo.png';

import C1 from './assests/c1.png';
import C2 from './assests/c2.png';
import C3 from './assests/c3.png';
import C4 from './assests/c4.png';
import C5 from './assests/c5.png';
import C6 from './assests/c6.png';
import C7 from './assests/c7.png';
import C8 from './assests/c8.png';
import C9 from './assests/c9.png';
import C10 from './assests/c10.png';
import C11 from './assests/c11.png';
import C12 from './assests/c12.png';
import C14 from './assests/c14.png';
import C15 from './assests/c15.png';
// import C16 from './assests/c16.png';


import P1 from './assests/p1.png';
import P2 from './assests/p2.png';
import P3 from './assests/p3.png';
import P4 from './assests/p4.png';
import P5 from './assests/p5.png';
import P6 from './assests/p6.png';
import P7 from './assests/p7.png';
import P8 from './assests/p8.png';
import P9 from './assests/p9.png';
import P10 from './assests/p10.png';
import P11 from './assests/p11.png';
import P12 from './assests/p12.png';
import P13 from './assests/p13.png';
import P14 from './assests/p14.png';
import P15 from './assests/p15.png';
import P16 from './assests/p16.png';
import P17 from './assests/p17.png';
import P18 from './assests/p18.png';
import manangementTeam1 from './images/managementTeam1.jpg'
import manangementTeam2 from './images/managementTeam2.png'

const Home = () => {
  return (
    <>
     <Header/>
     <Slider />
   
 <div className="seenm">
 <div className="homefooter"><Contact/></div>
    <Cprofile/>
    <Partnerss/>
    <ManagementTe/>

    <NetworkI/>
    <SecurityD/>
    <SystemD/>
    <ApplicationData/>
    <TrainingD/>
   
 </div>
 <Footer/>
    </>
  )
}

export default Home

const Contact = () =>{
  return(
    <div className="alignc1">
    <div className="animatebg1">
       <br/><br/>
       <br/>  <div className="headdding">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contact Us !</div><br/>

       <p><b>Global eNetworks Ltd </b><br/> 33, Rani Jhansi Road,<br/>  New Delhi – 110055,<br/> India</p><br/>
           <div className='flex'>< FiPhone className='ico'/>&nbsp;&nbsp;<p><b>Ph:</b>  + 91-11-23673393,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; + 91-11-23518502</p></div>   
           <div className='flex'><FiPrinter className='ico'/> &nbsp;&nbsp; <p><b>Fax:</b>+ 91-11-23556433</p></div>
           <div className='flex'><FiMail className='ico'/>&nbsp;&nbsp; <p><b>Email:</b>  info@globalenetworks.com</p></div>
           <div  >   <a href='https://www.google.co.in/maps/place/Global+eNetworks+Ltd/@28.6512695,77.2035924,17z/data=!4m5!3m4!1s0x390cfd65fecbbd3f:0x8293b78524c2ac3f!8m2!3d28.6512427!4d77.2058185' target='_blank' rel='noopener noreferrer'>
                 <img src={Map} alt =''  className='mapimg'/>
                  </a></div><br/><br/>
  
  
   </div>
   
   </div>
     
  )
}


const Cprofile = () =>{
  return(
    <>
    
   <div className="prof">
    <div className="pro">
    <br/>  <div className="headdding">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company Profile !</div><br/>
     <div style={{display:'flex'}}>
      <p><b>Global eNetworks Limited </b>was founded and promoted by young technocrats   heading the forefront of new technologies and new innovations in the IT and automation industry. The company is Part of the Rs 150 crore Bhai Asa Singh & Sons group having its headquarters in Delhi <br/><br/>Within a brief period, Global has grown to cater to various needs of clients. From the omnipresent personal computer, to enterprise servers, virtualization, onward to large network implementations and digital transformation - their designing, securing, implementing, maintaining and also extensively training the respective users at the organic levels in our fully equipped labs... Global vouchsafes a full-spectrum implementation of its esteemed subscribers.</p>
     <br/><br/><br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <div className='doen'><img src={Logi} alt= ' ' className='imagebody' /></div>
      </div>
   
     <br/><br/>   <h3>Areas of Competence</h3>
       <p>Global is a network integrator and complete IT service provider with a focus on digital transformation. We provide full spectrum IT solutions to meet the needs of the clients. Our areas of competence are given below</p>
  
     <br/> <h3><a href="/networkinfra">A) Network Infra Work Contracts </a> </h3>
   
   
    <br/>
    <h3> <a href="/securitysolution"> B) Security Solutions</a></h3>
    

    
    <br/>
    <h3><a href="/systemintegration">C) System Integration</a></h3>

    <br/>
    <h3> <a href="/applicationengineering">D) Application Engineering</a></h3>

    <br/>
    <h3> <a href="/trainings">E) Trainings</a> </h3>
   

    <br/>
    <h3>Key Projects</h3>
    <p>Global eNetworks has to it’s credit OFC deployments of more than 5000 kms and has contributed to end point energization vide UTP cabling and/or running applications for more than 80000 devices.
    <br/><br/> We have done various system and network integration projects for BEL, ECIL IBM, Indian Navy, ERNET India, Tech Mahindra, Delhi University, JNU, Jamia Millia Islamia University, Allahabad University, IIT Madras, Indian Air force, Indian Army, BEG-Roorkee, University of Rajasthan and many more esteemed clients.
    <br/><br/> Some of the projects executed by Global are as follows:
     </p>

     <p><b>CICP</b>--- Computerized Inventory control project. This was done along with IBM/L & T and involved deploying 250 kms of OFC Cabling work for the Indian Army across the Nation along with UTP cabling.
         <br/><br/><b>Messaging System for the Indian Navy</b>--- we have the privilege of having implemented the messaging project for the Indian Navy PAN India with state of the art hardware, server OS and virtualization, user nodes and network services and messaging software application installation using open source database.
         <br/><br/><b>OFC Laying project for the Infantry, Indian Army</b>--- Our company rolled out the OFC along with M/s PCS Tech across all infantry regimental centers across the Nation involving more than 500 kms of OFC Laying using the best practices of trenching and ducting/OFC Laying
         <br/><br/><b>Last Mile connectivity for the Indian Navy Backbone network</b>--- The OFC connectivity for the Indian Navy across India was implemented end to end using OFC , UTP cabling of more than 100 kms in the Naval Areas for facilitating the network access of remote units to the Naval backbone Network using our patented outdoor Enclosures along with Cisco active devices and numerous network accessories. 
           </p>
   <div className="chan">
    <p>
  <b  >Indian Air Force - Integrated Material Management Online Systems (IMMOLS) :</b>   The project involved supply & installation of Hardware, Software, Network Items and associated peripherals for 26 Additional sites of Indian Air Force for IMMOLS Application. The implementation involved installation of around 600 nodes over UTP CAT-6 and approximately 27 Kms of fibre optic cabling.
<br/><br/>& many more technology deployments in the Armed Forces /Defence Sector
<br/><br/> <b>ERNET India (An Autonomous Scientific Society under Department of Information Technology, GoI) - Outsourcings of Technical Manpower for Various PoP of ERNET India :</b>  ERNET India is providing Intranet and Internet connectivity to Educational and Research Organizations in the country. The connectivity is provided through its various Point of Presence (PoPs) across the country. These PoPs are connected on terrestrial backbone and are equipped with state of the art equipments such as Servers, Firewall, Antivirus Gateway, High-End Router and RAS. For this kind of requirement we are providing technical services to ERNET India for managing their PoPs. As on date we have positioned 20 Engineers for the same.

<br/><br/><b>IIT Chennai : </b>India's first 10 Gigabit Campus Wide Network implemented using 24/12 core optical fiber cables connecting all the staff quarters, hostels and departments. Over 5000 nodes form part of the Igloo net. The key differentiating factor of this project is the installation of Core LAN switches housed in weather proof racks in open areas having cooling units and remote monitoring capabilities.
<br/><br/><b>Allahabad University - Campus Wide Network :</b> The project focused on design, installation, configuration and maintenance of Campus Wide Network including supply and laying of fiber and UTP cable and components. The implementation involved installation of around 2000 nodes over Cat 5E UTP cable and approximately 20 Kms of Fiber Optic cable.
<br/><br/><b>Delhi University - Campus Wide Network :</b> The project consisted of setting up a campus network to connect all the colleges, departments and hostels of Delhi University. It encompasses 3200 nodes connected on Cat 5E UTP cable and 43 Kms of Fiber Optic cabling.
<br/><br/><b>Jamia Milia Islamia University - Campus Wide Network : </b>The project focused on design, installation, configuration and maintenance of Campus Wide Network including supply and laying of fiber and UTP cabling and components. The Implementation involved installation of around 750 nodes over Cat 5E UTP cable and approximately 10 Kms of Fiber Optic cable and energising the network using Cisco switches.
<br/><br/><b>DoTSoft Implementation for Department of Telecommunications : </b>(now known as Bharat Sanchar Nigam Ltd). The project involved installation and commissioning of systems, network and application software spread across more than 100 locations for automated billing system in Jammu and Srinagar Circles. It involved setting up of LAN and WAN using Cisco, RAD and 3Com equipment.
<br/><br/><b>Computerisation at Engineer-in-Chief Branch, Army Headquarters : </b>The project involved supply and installation of 525 Nos. of Personal Computers and associated peripherals for various establishments of Engineer-in-Chief branch. 
<br/><br/><b>Internetwork connectivity at Regional Engineering College, Kurukshetra :</b> The project involved supply and installation of Cisco routers with the associated peripherals for Internetwork Connectivity at the Regional Engineering College, Kurukshetra. 
<br/><br/><br/><br/>Contact us for any requirements at <span style={{color:'blue'}}> Info@GlobaleNetworks.com</span>
    </p>
    
   </div>
   
   
    </div>
   </div>

    </>
  )
}

const ManagementTe = () =>{
  return(
    <div className='manage'>
    <h3>Our Management Team</h3>
  <p>Global eNetworks is led and founded by <b> Sarpreet Singh Chadha </b> , BE (Electronics & Communications) from Delhi Institute of Technology affiliated to Delhi University (now NSUT).
He has wide experience of working in the IT Industry for more than 25 years and has spearheaded many an important project like the DOTSOFT project in critical areas like 100+ remote areas in Jammu and Kashmir  from 1999 till 2007,  Delhi University CWN project, IIT Madras Igloonet project, INS Vikramaditya ALAN project, Last mile connectivity project for the Indian Navy, messaging project for the Navy , countrywide deployment of OFC for all infantry regimental centers and many others.
</p>
<p>  <b>Pradeep Kumar-</b>  Pradeep is a B Com graduate from Delhi University with more than 24 years’ experience and  leads the finance and accounts work at Global eNetworks. Adoption of countrywide system of daily settlement of accounts Project wise has been his seminal contribution to the company.  A man of integrity and discipline , Pradeep inspires the youngsters in the company by his poise and honesty. 

<br/>

{/* <br/><br/> <b>Ajay Kumar---</b>   Ajay is a BE from IETE in Electronics & Telecommunications & MBA. A tireless and sincere engineer with a keen mind , Ajay absorbs the minutest details while configuring the active devices and various sub systems --- he carries the responsibility of enabling the various multi OEM active devices to talk to each other. He excels in counseling the team of system engineers for the countrywide deployments for specific project that he is assigned to. Ajay too has experience of 16 years with the company. */}

<br/><br/><b>Anu Dhamija---</b>    Anu , who has been with us for 15 years, is a graduate from Kurukshetra University ,and  is the company service  coordinator and contributes immensely for ensuring the uptime for the client projects as per the committed SLAs. She also looks after logistics support for the company to ensure timely material deliveries. Her focus and determination to meet timelines make her an asset to the organization.

</p>
<img src={manangementTeam1} alt="" className='imagia' />
<img src={manangementTeam2} alt="" className='imagia' />

  </div>
 

  )
}


const Partnerss = () =>{
  return(
    <>
     <div className='partner'>
      <h1>Our Partners</h1>
      <p>We associate with strategic innovators in the field of technology to provide our customers the access to the latest IT solutions and premium technical services. Together, we are helping government and corporate sectors with digital transformation and benefit from each other’s knowledge and experience.</p>
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P1} alt="" className='imageclient'/>
        <img src={P2} alt="" className='imageclient'/>
        <img src={P3} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P4} alt="" className='imageclient'/>
        <img src={P5} alt="" className='imageclient'/>
        <img src={P6} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P7} alt="" className='imageclient'/>
        <img src={P8} alt="" className='imageclient'/>
        <img src={P9} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P10} alt="" className='imageclient'/>
        <img src={P11} alt="" className='imageclient'/>
        <img src={P12} alt="" className='imageclient'/>
       
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P13} alt="" className='imageclient'/>
        <img src={P14} alt="" className='imageclient'/>
        <img src={P15} alt="" className='imageclient'/>
       
        
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of Our OEM Partners</h2>
        <div className="clint">
        <img src={P16} alt="" className='imageclient'/>
        <img src={P17} alt="" className='imageclient'/>
        <img src={P18} alt="" className='imageclient'/>
       
       
        </div>  
        <h5>All Logos and Trademarks are property of their Respective Owners</h5> 
      </div>
      <div className='partner'>
      <h1>Our Clients</h1>
      <p>We associate with strategic innovators in the field of technology to provide our customers the access to the latest IT solutions and premium technical services. Together, we are helping government and corporate sectors with digitization and benefiting from each other’s knowledge and experience.</p>
      </div>
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C1} alt="" className='imageclient'/>
        <img src={C2} alt="" className='imageclient'/>
        <img src={C3} alt="" className='imageclient'/>
        
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C4} alt="" className='imageclient'/>
        <img src={C5} alt="" className='imageclient'/>
        <img src={C6} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C7} alt="" className='imageclient'/>
        <img src={C8} alt="" className='imageclient'/>
        <img src={C9} alt="" className='imageclient'/>
       
        </div>  
      </div>
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C10} alt="" className='imageclient'/>
        <img src={C11} alt="" className='imageclient'/>
        <img src={C12} alt="" className='imageclient'/>
       
        </div>  
      </div> 
      <div className="clientu">
        <h2>Some of our Clients</h2>
        <div className="clint">
        <img src={C14} alt="" className='imageclient'/>
        <img src={C15} alt="" className='imageclient'/>
       
        </div> 
        <h5>All Logos and Trademarks are property of their Respective Owners</h5> 
      </div> 

    </>
  )
}


const ApplicationData = () =>{
  return(
    <>
     <div className="appl1">
    <div className="appl2">
    <br/>  <div className="headdding">Application Engineering</div><br/>
    <p>Global eNetworks boasts of strong capabilities in delivery of application engineering 
solutions. It has resources adept in various frameworks & Languages like <b>.Net, Java, php , 
Python, react js, & node js.</b>Global has embarked on the mission to help organizations adopt 
state of the art devOps tools for maximizing productivity and minimizing costs. SDLC is 
a capability on which Global eNetworks has a big focus on.


<br/><br/><b>We have resources  working on AI(Artificial Intelligence),ML (Machine Learning) tools like Tensorflow, R</b>

<br/><br/><br/><br/>Global eNetworks has deployed applications of mission critical nature for Clients 
of National importance like the Indian Navy. It has a legacy of years of work in this 
area of application specific solutions like the ASMS tools in the Indian Air Force 
and the tools for integrating Open source db with inventory control applications 
like IMMOLS for the Indian Air Force.
<br/><br/>Global has its roots in the development of vendor /inventory control application 
integration of Automobile giant MARUTI UDYOG with its vendors in the North 
region of the country when it did pioneering work in this regard many years back. 
The company has carried on with it‘s legacy of state of the art application 
deployments.

Company has a strong application engineering team for your app development 
requirements and we look forward to your enquiries in this regard. 
</p>
    </div>
   </div>
   
    </>
  )
}


const NetworkI = () =>{
  return(
    <>
    <div className="appl">
    <div className="netu2"> 
    <br/>  <div className="headdding">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Network Infra</div><br/>
    <p>We build end to end network Infra , starting from greenfield OFC construction in civil 
areas wherein our teams our adept at working in safe and efficient manner along with 
OFC laying in campus areas, onwards to indoor UTP cabling …
 we also do the server builds and Data Center low level works with OEMs like Vertiv and 
Rittal. We were the pioneers of adoption of the fiber channel based fabric interconnect 
based blade chassis installations wherein we have to our credit the first public sector 
blade Chassis/FI build deployment for Cisco UCS in India in the year 2010. 

      <br/><br/>  <b>Global eNetworks has to its credit experience of OFC deployments of more than 5000 kms.</b> It has delivered projects which have enabled energization of more than 80000 end points/User devices. 
Building Network Infra is a speciality of Global eNetworks . It has partnered with renowed Public sector units like BEL, ECIL and ERNET India to deliver and build OFC networks of national importance like-
LMC Connectivity across the Indian Navy
Delhi university ---PAN university network connecting all hostels/colleges/south and north campus
NHQ MAN (All Directorates/Secretariats---- across many locations in Delhi using OFC /UTP/Active Devices)
18 Infantry regimental centers of the Indian Army using more than 500 kms of OFC
CICP --- asset of 250 kms OFC was built across many units across the Nation in record time 

And several other projects across the country

Global eNetworks holds the design patent for the Marine Enclosure designed by it for outdoor use--- it is used for housing the active devices for LMC networks.     

<br/> <br/>We have several years of experience in putting together and integrating complex networks that meet your exact business requirements. Based on an understanding of your existing infrastructure, we design and implement a network that will keep pace with your company's growth. 
    <br/><br/> Given the fact, Security in any network is of utmost importance; Global eNetworks has a special focus in this domain. We not only provide connectivity by building networks but we do so by providing the connectivity in a secure manner. Special emphasis is given on designing security solutions, which helps enterprises protect their business information.
    <br/><br/>  We have a team of professionals which brings together a variety of experience of working in different environments such as Microsoft, Linux and VMWare and on different LAN/WAN technologies and on different Security products and tools such as Next Gen Firewalls, VPN, Intrusion detection, Anti APT etc. Our in-depth knowledge of internetworking products such as routers, multiplexers, switches, firewalls, security tools and cabling products and standards etc from industry leaders such as Cisco, VMWare, Microsoft, A10, NetApp, Palo Alto, Acer, Molex, Dlink, VTL, Paramount etc provides us an edge over our competitors.
    <br/><br/> <b> Our networking and security solutions include:
    <br/>   •	LAN/WAN design & implementation
    <br/>   •	Network auditing & assessment
    <br/>   •	Internet security/firewall/VPN design & implementation
    <br/>   •	Network management, analysis & planning
    <br/>   •	Data & voice integration
    <br/>   •	LAN/WAN Quality of Service solutions
    <br/>   •	Load balancing and fault tolerance solutions
    <br/>   •	Internet connectivity
    </b>
</p></div>
  
   </div>
   
   </>
  )
}


const SecurityD = () => {
  return(
    <>
    
  <div className="serv">
    <div className="serv2"> 
    <br/>  <div className="headdding">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;Security Solutions</div><br/>
     <p>Given the fact, Security in any network is of utmost importance; Global eNetworks has a special 
focus in this domain. We not only provide connectivity by building networks but we do so by 
providing the connectivity in a secure manner. Special emphasis is given on designing security 
solutions, which helps enterprises protect their business information.


      
      <br/><br/><b>Global eNetworks Ltd’s</b> first ever project was from a Defence organization of the Navy for deployment of computer security product and services. After that it has gone from strength to strength in delivery and successful life cycle management of IT service projects.
It has recently worked with Tech Mahindra for a SOC Operations Services project. 
Company has extensive work experience of outsourcing of IT services from ERNET India for which it used to manage the NOC/SOC operations in many locations country wide.
Global eNetworks  is actively rendering IT services for mission critical messaging and LMC projects of the Indian Navy and has assigned multiple resources to deliver the IT services SLA to the users.
We provide top quality resources at affordable prices, our resources have integrity and domain knowledge & they are assets to the organization where ever assigned.
<br/>Every organization faces challenges that are peculiar to its business and its vision for the future. Accordingly, the system integration needs of different enterprises would vastly differ for enhancing productivity using digital means.
       <br/><br/>The digital needs of a Defence organization will differ than that of a  corporate—the former may have more focus on security and will require on premise digital deployments for it’s mission critical projects as compared to the latter who may not hesitate to have cloud based deployment for projects like online sales and logistics. 
      
       <br/><br/> 
         Keeping this in mind, Global has developed the capability to offer end-to-end system 
         integration security solutions custom-designed to meet all business need. In fact, we 
         excelled in the area of providing robust and current security solutions. 
         <br/><br/><b>We do end to end Internet security/firewall/VPN design & implementation / IAM /PAM / Patch Management Solutions</b>
       <br/><br/> We have the support of global leaders and Indigenious Players to deliver the latest technologies at competitive prices. We have also acquired expert knowledge in several core technologies to offer the right solutions to our customers. Our team of skilled engineers uses best practices and proven methodologies to manage mission-critical projects that gives our customer competitive advantage over others. Besides, our countrywide service ensures that you get the best service and digital deployment for your specific needs when and where you need it.
       </p></div>
   
  </div>
  
   
    </>
  )
}


const SystemD = () =>{
  return(
    <>
    <div className="bppl1">
      <div className="bppl2">
      <br/>  <div className="headdding">&nbsp;&nbsp;&nbsp;&nbsp;System Integration</div><br/>
     <p>
    <b> Today the biggest challenge is to get different OEM builds to talk to each other 
seamlessly with as low latency as possible. And our engineers are experts exactly 
at this.</b>

<br/><br/>We have a team of professionals which brings together a variety of experience of working in 
different environments such as Microsoft, Linux and VMWare and on different LAN/WAN 
technologies and on different Compute/storage/Security products and tools such as HCI, blade 
servers, SAN/NAS Storage ,Rack servers, Next Gen Firewalls, VPN, Intrusion detection, Anti APT, 
IDAM/PAM solutions, patch management solutions etc. Our in-depth knowledge of 
internetworking products such as routers, servers, switches, storage, firewalls, security tools and 
digital solutions from industry leaders such as Cisco, VMWare, Microsoft, A10, NetApp, Palo 
Alto, Acer,  Dlink, etc provides us an edge over our competitors.
<br/> <br/><b>Our Team has Strong Skills in Virtualization and Backup Solutions as also Containerization of Critical Apps and Builds.</b>

<br/><br/>
The first ever public sector deployment of Cisco blade servers in a complex application porting 
environment on Linux was done by us in the year 2010 …. This involved integration with TACACS 
server ISE based security appliances along with ASA Series firewalls and encryption equipment 
of the highest grade on carrier grade routers backbone networks PAN Nation.
<br/><br/>Keeping in view the indigenous push for the local products we have tied up with local OEMs like 
Gajshield, Infinity Labs, eMudhra, StackUp , Motadata for their solutions like Patch management, 
Next Gen Firewalls, SDWAN, Hyper Converged Infra, software defined storage (SDN) and more….

<br/><br/>
Our huge collective experience of our engineers and technicians solve problems of complex 
nature involving system integration needs of our clients in a quick and safe manner. 



     </p>

      </div>
    </div>
    </>
  )
}


const TrainingD = () => {
  return(
    <>
    
    <div className="appl1">
      <div className="appl2"> 
      <br/>  <div className="headdding">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trainings</div><br/>
      <p>
        
      We believe in not just attaining new skills and knowledge for ourselves, but we also help our customers to attain more skills and knowledge so that they can use IT more efficiently and effectively to run their business. For this, we utilize facilities of our state of the art lab at Delhi to impart training to the staff of our customers. We have already successfully imparted trainings to the network professionals and consultants of <b>Wipro, Price Waterhouse, NIIT, Microland, Ranbaxy,</b>  Estel Networks, HCL Technologies, Hughes Escorts Communications Limited, Indian Navy, and various representatives of the Universities under UGC,& personnel of IARI, ERNET India etc.
    <br/><br/>   We have some of the best instructors for our systems and network courses and having extensive experience of implementing large corporate networks. The training offerings address both certification requirements as well as customized needs. The current offerings cover the complete IT needs right from the basic user level training for familiarization to Computers and Networks to advanced and complex concepts such as<b> Security, data/voice integration, special projects etc.</b>
    <br/><br/>
Contact us for corporate trainings at Info@GlobaleNetworks.com
<br/><br/>Information on the Internet is cluttered and focused training modules are the need of the day. We provide professional corporate training to a wide range of industries.
<br/><br/>  <b> Training Modules</b>
<br/>Our corporate training modules include:
<br/>•	Networking
<br/>•	Systems Curricula
<br/>•	Security
<br/>•	Computers & Office Automation
<br/><br/><b>Training Imparted</b>
<br/><br/>Global eNetworks has successfully imparted trainings to network professionals and consultants of known names such as Wipro, Price Waterhouse, NIIT, Microland, Ranbaxy, Estel Networks, HCL Technologies, Hughes, and Escorts amongst others.
          Our state-of-the-art lab in New Delhi is well equipped for imparting training to company staff and consultants.

          <br/><br/><b>Instructors</b>
          <br/><br/>Our instructors are some of the best in the industry, trained in Singapore with extensive experience of implementing large corporate networks. The training covers both certification requirement as well as customized need.

          <br/>InstructorsThe current training courses cover complete IT needs right from basic level training, familiarizing with computers and networking, to advanced and complex concepts like Security, Data/Voice Integration etc.


     </p></div>
     
    </div>
   
  
    
    </>
  )
}