import React, { useState } from 'react'
import Header from '../header/Header'
import './Order.css'

import Radio from '@mui/material/Radio';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



const Orders = () => {
  return (
    <>
      <Header />
      <Ordersdata />
    </>
  )
}

export default Orders

const Ordersdata = () => {

  const [selectedValue, setSelectedValue] = useState('a');
  const [next1, setNext1] = useState(true);
  const [next2, setNext2] = useState(false);
  const [next3, setNext3] = useState(false);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const [nodes, setNodes] = useState('');
  const [area, setArea] = useState('');
  const [lanswitch, setLanswitch] = useState('');
  const [rack , setRack] = useState('yes');
  const [mdevices, setMdevices] = useState('');
  const [addlanswitch, setAddlanswitch] = useState('');
  
  const options = [{label:'Cysko', value:'cysko'},
                  {label:'Digital', value:'digital'},
                  {label:'Digisol', value : 'digisol'},
                  {label: 'Digilink',value:'digilink'},];

   const options2 = [{label:'yes', value:'yes'},
                  {label:'no', value:'no'},];

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

 



  const NextChan = () => {
    return (
      setNext1(!next1),
      setNext2(!next2)
    )
  }

  const NextChan2 = () => {
    return (
      setNext2(!next2),
      setNext3(!next3)
    )
  }

  

 

  return (
    <>
      <div className='orde'>
        <div className="orde2">
          <h1>Hello.. Welcome to Our Service Order estimation Page</h1>
          <h2>We warmly Appreciate Your accompany and happy To help you. Please Follow Instruction in order to Help You</h2>
          <div className="form">
            <div  className={next1 ? "formin" : "ifnone"}>

              <p>Are you located in Delhi / Delhi NCR </p>

              <div style={{ display: 'flex' }}>
                <p>yes</p>
                <Radio
                  checked={selectedValue === 'a'}
                  onChange={handleChange}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'A' }}

                />
              </div>
              <div style={{ display: 'flex' }}>
                <p>No</p>
                <Radio
                  checked={selectedValue === 'b'}
                  onChange={handleChange}
                  value="b"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'B' }}
                />
              </div>
            </div><br /><br />
            <div className={selectedValue === 'b' ? 'ifno' : 'ifnone'}>
              <h3>Sorry! We are Deeply regreted We cannot offer Our Service outside the Area of Delhi and  Delhi NCR. You can Call for more information .</h3>
            </div>

            <div className={selectedValue === 'b' ? 'ifnone' : 'ifyes'}>
              <div className={next1 ? "ifyes" : "ifnone"}>
                <TextField
                  helperText="Please enter your name"
                  id="demo-helper-text-misaligned"
                  label="Name"
                  className='inputtext'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <br /><br />
                <TextField
                  helperText="Enter Phone no without +91"
                  id="demo-helper-text-misaligned"
                  label="Phone"
                  className='inputtext'
                  value={phone}
                  type='number'
                  maxLength={5}
                  onChange={(e) => setPhone(e.target.value)}
                />
                
                <br /><br />
                <TextField
                  helperText="Please enter your Address"
                  id="demo-helper-text-misaligned"
                  label="Address"
                  multiline
                  rows={4}
                  className='inputtext'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <br /><br />
                <Button variant="contained" onClick={NextChan}>Next </Button>
              </div>


              <div className={next2 ? "ifyes" : "ifnone"}>
              <div className='textfield-flex'>
                <TextField
                  helperText="How many number of desktops and/or servers are to be connected on Ethernet cable (maximum 24) "
                  id="demo-helper-text-misaligned"
                  label="Nodes/Systems"
                  className='inputtext'
                  value={nodes}
                  type="number"  min="01" max="24" 
                  onChange={(e) => setNodes(e.target.value)}
                />
                <br /><br />
                <TextField
                  helperText="What is the area of the premises where cabling is to be done (in square feet)"
                  id="demo-helper-text-misaligned"
                  label="Area in sq.feet"
                  className='inputtext'
                  value={area}
                  type="number"  min="50" max="3000" 
                  onChange={(e) => setArea(e.target.value)}
                />
               </div>

                <br /><br />
                <div style={{display : 'flex'}}>
              <p>What is your choice of the LAN Switch , please choose from the following options :- </p>
               <Dropdown
               labal= "select"
               value={lanswitch}
               options={options}
               onChange={(e) => {setLanswitch(e.target.value)}}
               />
               </div>
                <br /><br />
                <div style={{display : 'flex'}}>
                <p>Do You already have a rack for housing the LAN switch ? </p>
                <Dropdown
               labal= "  "
               value={rack}
               options={options2}
               onChange={(e) => {setRack(e.target.value)}}
               />
                </div> 
              <br /><br />
               <div style={{display : 'flex'}}>
               <p>Is there any desktop or server which is more than 80 meters away from the  point where the LAN switch will be placed- If yes, Now please Mention the No. of devices Greater than 80m away from LAN Switch rack Location </p>
              
               
               <input 
                 type='number' 
                 value={mdevices} 
                 onChange={(e)=>setMdevices(e.target.value)}
                 className='laninput'   
                 placeholder='No of devices'
                 />
               
               </div>
                <br /><br />

                <div style={{display : 'flex'}}>
                  <p>Please Choose Additional Switch from the following options-</p>
                  <Dropdown 
                   labal= "Select"
                   classname ='dropdowninputs'
                   value={addlanswitch}
                   options={options}
                   onChange={(e) => {setAddlanswitch(e.target.value)}}
                  />
                </div>
                <br /><br />
                <div className='button-flex'>
                <Button variant="contained" onClick={NextChan}>Back </Button>
                
                <Button variant="contained" onClick={NextChan2}>Next </Button>
                </div>
               
              </div>
              <div className={next3 ? 'ifyes':'ifnone'}>
                <h5>Your Bill Of Material is ready ..thankyou!!</h5>
               <p> Name of user is : {name}</p>
               <p>Phone number is : {phone}</p>
               <p>Your Given address is : {address}</p>
               <p>Your Want to connect no of nodes/ computer is : {nodes}</p>
               <p>Your total area is : {area}</p>
               <p>Your Lan switch company : {lanswitch}</p>
               <p>Needs of Rack : {rack}</p>
               <p>company of additional switch company: {addlanswitch}</p>

              </div>
            </div>

          </div>


        </div>

      </div>


    </>
  )
}

const Dropdown = ({value,onChange,options,labal}) =>{
  return(
    <>
    <label className='dropdownlabel'> {labal}
    <select className='dropdowninputs' value={value} onChange={onChange}>
      
      {options.map((option)=> (
        <option  value={option.value}>{option.label}</option>
      ))}
      
    </select>
    </label>
    <br/>
    <br/>
  
    </>
  )
}

