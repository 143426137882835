import React from 'react'

import Header from '../header/Header'
import Footer from "../footer/Footer";
import './Contact.css'
import { FiPhone, FiMail, FiPrinter } from "react-icons/fi";

import Map from '../assests/map.png';

const Contact = () => {
  return (
    <>
    <Header/>
   
 
  
    <Heading/>
  
    
    
   
     <Footer />
  
    </>
  )
}

export default Contact


const Heading = () =>{
  return(
    <div className="alignc1">
    <div className="animatebg1">
       <br/><br/><br/>
       <p><b>Global eNetworks Ltd </b><br/> 33, Rani Jhansi Road,<br/>  New Delhi – 110055,<br/> India</p><br/>
           <div className='flex'>< FiPhone className='ico'/>&nbsp;&nbsp;<p><b>Ph:</b>  + 91-11-23673393,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; + 91-11-23518502</p></div>   
           <div className='flex'><FiPrinter className='ico'/> &nbsp;&nbsp; <p><b>Fax:</b>+ 91-11-23556433</p></div>
           <div className='flex'><FiMail className='ico'/>&nbsp;&nbsp; <p><b>Email:</b>  info@globalenetworks.com</p></div>
           <div  >   <a href='https://www.google.co.in/maps/place/Global+eNetworks+Ltd/@28.6512695,77.2035924,17z/data=!4m5!3m4!1s0x390cfd65fecbbd3f:0x8293b78524c2ac3f!8m2!3d28.6512427!4d77.2058185' target='_blank' rel='noopener noreferrer'>
                 <img src={Map} alt =''  className='mapimg'/>
                  </a></div><br/><br/>
  
  
   </div>
   
   </div>
     
  )
}
